import React, { useState } from 'react';
import Head from 'next/head';
import mixpanel from '@/lib/util/mixpanel';
import {
  Form, Button, Col, Row, Spinner,
} from 'react-bootstrap';

import useForm from '@/lib/hooks/useForm';
import { useUserContext } from '@/lib/context/useUserContext';
import Layout from '@/components/common/layouts/Layout';
import interfaceConfig from 'interfaceConfig';
import Image from 'next/image';
import LineWithText from '@/components/common/LineWithText';
import { login } from '@/lib/api/Auth';
import LoginWithGoogle from '@/components/auth/login/LoginWithGoogle';
import ReCaptchaV2 from 'react-google-recaptcha';

function Login() {
  return (
    <Layout>
      <Head>
        <title>Big & Mini: Login</title>
      </Head>
      <div className="fill-height">
        <Row>
          <Col lg={5} sm={0} className="login-style" style={{ backgroundImage: `url(${interfaceConfig.loginImage})` }}>
            {/*
            <Row className = "signup-image">
              <img src={image}
                width="90%" />
            </Row> */}
          </Col>
          <Col lg={1} />
          <Col lg={5} sm={12}>
            <div style={{ position: 'absolute', top: 30, right: 30 }}>
              <Image
                src="/images/combined_logo.png"
                alt="Purpose Pals + Big & Mini"
                width={216}
                height={80}
              />
            </div>
            <br />
            <br />
            <LoginForm />

          </Col>
          <Col lg={1} />
        </Row>
      </div>
    </Layout>
  );
}

function LoginForm() {
  const { signIn } = useUserContext();

  const [message, setMessage] = useState('');
  const [isLoadingIndicator, setIsLoadingIndicator] = useState(false);
  const [shouldShowRecaptcha, setShouldShowRecaptcha] = useState(false);

  const onLogin = () => {
    // eslint-disable-next-line no-use-before-define
    const { email: originalEmail, password, recaptchaToken } = formFields;

    setMessage('');
    setIsLoadingIndicator(true);

    const email = originalEmail.toLowerCase();
    console.log(formFields);

    login({ email, password, recaptchaToken })
      .then((userData) => {
        mixpanel.identify(userData.email);
        mixpanel.track('Login');
        signIn(userData);
      })
      .catch((err) => {
        // display an error message
        setMessage(err.message);
        setIsLoadingIndicator(false);
        if (err.message.includes('reCAPTCHA')) {
          setShouldShowRecaptcha(true);
        }
      });
  };

  const {
    formFields, handleInputChange, handleSubmit,
  } = useForm(onLogin);
  const handleToken = (token) => {
    handleInputChange({ target: { name: 'recaptchaToken', value: token } });
  };
  const handleExpire = () => {
    handleInputChange({ target: { name: 'recaptchaToken', value: null } });
  };
  return (
    <div className="complete-centered">
      <h1 className="bolded d-inline-block">Log in</h1>

      <LoginWithGoogle signIn={signIn} setMessage={setMessage} />
      <Form onSubmit={handleSubmit}>

        <LineWithText text="Or" />

        <Form.Group controlId="formGridEmail">
          <Form.Label>Email</Form.Label>
          <Form.Control onChange={handleInputChange} name="email" value={formFields.email || ''} type="email" required />
        </Form.Group>

        <Form.Group controlId="formGridPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control onChange={handleInputChange} name="password" value={formFields.password || ''} type="password" required />
        </Form.Group>
        {shouldShowRecaptcha && (
        <ReCaptchaV2
          onChange={handleToken}
          onExpire={handleExpire}
          sitekey="6LcFOf4UAAAAAERQl7a2eL-ky3M5d7ha_p5kc6K4"
        />
        )}
        <br />
        <Button className="large-signup-button" type="submit">
          {isLoadingIndicator
            ? (
              <Spinner animation="border" size="sm">
                <span className="sr-only">Loading...</span>
              </Spinner>
            )
            : 'Log in'}
        </Button>

        <div style={{ color: 'red' }}>
          {' '}
          {message}
        </div>

        <div>
          <br />
          <br />
          <p className="inline">Don&apos;t have an account? </p>
          <a className="inline" href="signup"> &nbsp;Sign Up</a>
          <p>
            Forgot password?
            {' '}
            <a href="forgotpassword">Click Here</a>
          </p>
        </div>
      </Form>
    </div>
  );
}

export default Login;
